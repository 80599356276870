<template>
  <div class="loan_item">
    <div class="inner inner1 flex ai-c">
      <div>
        <h1>Sobre nosotros</h1>
        <p>
          Caudalsube es una aplicación financiera en línea en la que los usuarios pueden
          solicitar préstamos personales de hasta  $ 30,000 en cualquier momento y lugar.
          Ofrece servicios de préstamo flexibles y buenos. Ofrece moneda completa en
          línea a través de plataformas seguras, sin ninguna hipoteca.
        </p>
        <p>
          Nuestra empresa ofrece préstamos financieros instantáneos y soluciones de
          préstamos personales. No solo apoyamos su consumo personal (como
          atención médica personal, primas de seguro, inversiones, gastos de boda,
          viajes, transporte, gastos educativos, etc.), sino que también le ayudamos
          a obtener préstamos con flexibilidad y resolver sus necesidades de
          financiación a través de métodos técnicos innovadores.
        </p>
      </div>
    </div>
    <div class="inner inner2">
      <h1>Valores fundamentales</h1>
      <div class="items">
        <div class="left_item">
          <img src="@/assets/images/loanImg/icon_people.png" alt="">
        </div>
        <div class="right_item">
          <div class="item_info">
            <div class="info_tips">
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-1.png" alt="">
                <div>
                  <h3>Abierto</h3>
                  <p>Respeto y tolerancia, empatía</p>
                </div>
              </div>
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-2.png" alt="">
                <div>
                  <h3>Cooperación</h3>
                  <p>Colaboración y desarrollo continuo</p>
                </div>
              </div>
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-3.png" alt="">
                <div>
                  <h3>Innovador</h3>
                  <p>Más allá de la innovación y explorando el futuro</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="title">
        <h3>Nuestro principio de servicio</h3>
      </div>
      <div class="flex">
        <div v-for="(item,index) in inner3items" :key="index" class="content">
          <div class="flex fd-c">
            <img :src="item.img">
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner4">
      <!-- <h3>Por qué elegir Mipréstamo</h3> -->
      <div class="flex fd-c">
        <div v-for="(item,index) in inner_4items" :key="index" class="content flex" :class="index === 1 ?'fd-r jc-sb':''">
          <img :src="item.img">
          <div class="content2 flex fd-c jc-sb">
            <h1>{{ item.title }}</h1>
            <div v-for="(item2,index2) in item.list2" :key="index2">
              <p v-if="item2.txt">{{ item2.txt }}</p>
              <h2 v-if="item2.txt2">{{ item2.txt2 }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <div class="title">
        <h3>Contáctanos</h3>
      </div>
      <div class="flex items">
        <div v-for="(item,index) in inner5items" :key="index" class="content">
          <div class="flex fd-c ai-c">
            <img :src="item.img">
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner3items: [
        { img: require('@/assets/images/people1.png'), txt: 'Satisfacción del cliente', txt2: 'Queremos resolver una necesidad de dinero urgente con la mayor rapidez, eficacia y \n buen trato.' },
        { img: require('@/assets/images/people2.png'), txt: 'Transparencia', txt2: 'Recuerda que nuestra tasa de interés siempre es visible, nuestros préstamos son \n sin comisiones, letras chiquitas o costos ocultos. \n El único requisito es pagarlo en un lapso de 1 a \n 9 meses.' },
        { img: require('@/assets/images/people3.png'), txt: 'Responsabilidad', txt2: 'Sugerimos a nuestros clientes que pidan un préstamo rápido, solo si es su mejor opción de financiamiento.' }
      ],
      inner_4items: [
        { img: require('@/assets/images/inner_4_3.png'), title: 'Misión', list2: [{ txt: 'Utilizar tecnologías innovadoras para proporcionar servicios financieros más flexibles, confiables, convenientes y eficientes a los grupos de bajos ingresos y vulnerables.' }] },
        { img: require('@/assets/images/inner_4_4.png'), title: 'Visión', list2: [{ txt: 'Con el fin de resolver el problema del uso de los fondos y mejorar la calidad de vida de todos, nos comprometemos a proporcionar los mejores servicios de tecnología financiera a la sociedad.' }] }
        // { img: require('@/assets/images/inner_4_3.png'), title: 'Préstamo rápido', list2: [{ txt2: 'Una vez aprobado el sistema, el dinero se transferirá \n directamente a su tarjeta bancaria.' }] }
      ],
      inner6ItemLeftList: [
        { title: 'E-mail:pesogoviphelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: 'Address:170 TORRE VENEZIA SUITES TIMOG AVE. COR. SCT. SANTIAGO ST. LAGING HANDA', img: require('@/assets/images/address.png') },
        { title: 'Service Hours : 9:00 - 17:00', img: require('@/assets/images/time.png') },
        { title: 'SEC Registration No.CS201911897;CERTIFICATE OF AUTHORITY NO.3037' }
      ],
      inner5items: [
        { img: require('@/assets/images/people4.png'), txt: 'E-mail:', txt2: 'caudalsube@outlook.com' },
        { img: require('@/assets/images/people5.png'), txt: 'Dirección de la oficina:', txt2: 'Nueva 12,Acatlán de Pérez Figueroa,OAX,68422,Mexico' },
        { img: require('@/assets/images/people6.png'), txt: 'Tiempo laboral：', txt2: 'De lunes a viernes 9:00-17:00' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1400px;
    margin: 0 auto;
    overflow: hidden;
    padding: 50px calc((100% - 1100px) / 2) ;
    h3{
      font-size: 18px;
    }
    .content{
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner1{
    background: url('~@/assets/images/loan_bg.png') no-repeat;
    background-size: 100% 100%;
    height: 550px;
    display: flex;
    text-align: left;
    div{
      border-radius: 20px;
      padding: 0 550px 0 0;
      opacity: 0.8;
      h1{
        font-size: 40px;
        line-height: 140px;
        color: #FFFFFF;
      }
      p{
        color: #FFFFFF;
        font-size: 14px;
        text-align: left;
        width: 100%;
        line-height: 30px;
        padding: 0 0 60px ;
      };
    }
  }
  .inner2{
    // height: 420px;
    background:#FFFFFF;
    text-align: center;
    padding:80px calc((100% - 1100px) / 2) 100px;
    h1{
      margin-bottom: 60px;
      font-size: 28px;
    }
    h3{
      font-size: 16px;
    }
    .items{
      display: flex;
    }
    .left_item{
      flex: 1.2;
      text-align: end;
      img{
        width: 400px;
        height: 100%;
        // margin-right: 50px;
      }
    }
    .right_item{
      flex: 1.5;
      margin-right:30px;
      text-align: left;
      line-height: 30px;
      background: #F1F5FF;
      p{
        color:#333333;
        font-size: 18px;
        font-weight: bold;
      }
      .item_tips{
        display: flex;
        width: 460px;
        p{
          width: 70px;
          font-size: 12px;
          color: #777777;
          text-align: left;
        }
      }
      .item_info{
        // margin: 20px 0;
        height: 100%;
        display: flex;
        .info_img1{
          width: 40px;
        }
        .info_tips{
          width: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .items{
            flex: 1;
            display: flex;
            border-radius: 10px;
            padding-left: 60px;
            align-items: center;
            img{
              margin-right: 10px;
            }
          }
          .items:nth-last-child(1){
            margin-bottom: 0px !important;
          }
          p{
            font-size: 12px;
            color: #777777;
            line-height: 20px;
          }
        }
      }
    }
  }
  .inner3{
    position: relative;
    padding:100px calc((100% - 1000px) / 2) ;
    background: #2E69D5;
    .title{
      text-align: center;
      margin-bottom: 50px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 30px;
        color: white;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 35%;
      margin: 0 10px;
      padding-bottom: 20px;
      text-align: center;
      color: black;
      background: white;
      border-radius: 20px;
      div{
        height: 100%;
      }
      img{
        width: 100%;
        border-radius: 20px 20px 0 0;
      }
      h1{
        margin-top: 20px;
        font-size: 18px;
        line-height: 50px;
      }
      p{
        color: #666666;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 24px  0px;
      }
    }
  }
  .inner4{
    position: relative;
    padding:90px calc((100% - 1100px) / 2) 60px;
      // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 30px;
      color: black;
      margin-bottom: 60px;
      text-align: center;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      width: 100%;
      text-align: left;
      color:white;
      margin-bottom: 60px;
      background: #F1F5FF;
      h1{
        font-size: 24px;
        margin-bottom: 40px;
        // margin:0 0 50px 0;
        position: relative;
      }
      p{
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        // width: 70%;
        position: relative;
        margin-bottom: 20px;
        // &::before{
        //   position: absolute;
        //   display: block;
        //   content:'';
        //   width: 10px;
        //   height: 10px;
        //   border-radius: 5px;
        //   background: #0071D6;
        //   margin:0 10px 5px 0;
        //   left: -28px;
        //   top: 7px;
        // }
      }
      h2{
        color: #666666;
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 20px;
        font-weight: 100 !important;
        line-height: 23px;
      }
      img{
        width: 450px;
      }
    }
    .content2{
      width: 42%;
      text-align: left;
      color: black;
      margin-left: 50px;
      justify-content: center;
      p{
        color: #666666;
        font-weight: 100 !important;
        line-height: 23px;
      }
    }
  }
  .inner5{
    position: relative;
    padding:100px calc((100% - 1300px) / 2) ;
    background: #063EA4;
    .title{
      text-align: center;
      margin-bottom: 50px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 30px;
        color: white;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 33%;
      margin: 0 10px;
      padding-bottom: 20px;
      text-align: center;
      color: white;
      border-radius: 20px;
      div{
        height: 100%;
      }
      img{
        width: 50%;
        border-radius: 20px 20px 0 0;
      }
      h1{
        margin-top: 20px;
        font-size: 18px;
        line-height: 50px;
      }
      p{
        color: #FFFFFF;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 24px  0px;
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      height: 800px;
      color: white;
      padding: 10px 20px;
      flex-direction: column;
      background: #063EA4 !important;
      div{
        padding: 0 20px !important;
      }
    }
    .inner2{
      // height: 420px;
      background:#F8F9FF;
      text-align: center;
      padding: 50px 0 0;
      h1{
        width: 100%;
        margin-bottom: 50px;
      }
      .items{
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-bottom: 20px;
        padding-left: 0 !important;
        img{
          margin-right: 0 !important;
        }
      }
      .right_item{
        margin-right: 0;
        .item_info{
          text-align: center;
          .info_tips{
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
    .inner3{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw ) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      h3{
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .flex{
        flex-direction: column;
      }
      .content{
        width: calc(100% - 40px);
        margin-bottom: 50px;
        margin: 0 auto 40px;
        img{
          width: calc(100vw - 40px) !important;
        }
        div{
          width: calc(100vw - 40px) !important;
          p{
            font-size: 12px;
          }
        }
      }
      .title{
        width: 100%;
        margin-bottom: 20px;
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
      }
    }
    .inner4{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      padding: 50px 0px 0;
      div{
        width: 100%;
      }
      p{
        padding: 10px 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .content{
        flex-direction: column;
        margin-bottom: 20px;
        h1{
          font-size: 20px;
          margin: 20px 0;
        }
        img{
          width: 100% !important;
          margin: 0;
        }
        p{
          width: 80% !important;
          margin: 0 auto;
          font-size: 12px;
        }
      }
      .content2{
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center !important;
        padding: 0 !important;
        margin-left: 0;
        h2{
          margin: 0 20px;
          font-size: 12px;
          margin-bottom: 20px;
        }
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-button{
        padding: 0 10px;
        img{
          width: 10px;
        }
      }
      .carousel-container{
        height: 600px;
      }
      .carousel-wrapper{
        padding: 0 20px;
        flex-wrap: wrap;
        .carousel-item{
          width: 45%;
          margin: 0 5px;
        }
      }
      .carousel-item{
        width: 150px;
        height: 280px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          height: 40px;
          padding: 0;
          line-height: 16px;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 5px;
          line-height: 18px;
        }
      }
    }
    .inner5{
      .items{
        flex-direction: column;
        .content{
          width: 100%;
        }
      }
    }
  }
}
</style>
