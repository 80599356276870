<template>
  <div class="nav-inner">
    <div class="nav-tab">
      <div class="tab">
        <div class="hotel-logo">
          <span class="logo-img" />
          <span>CaudalSube</span>
        </div>
        <ul class="hotel-li">
          <li
            v-for="(item,index) in navData.list"
            :key="index"
            :class="navData.index === index ? 'select-item under-line': 'select-item'"
          >
            <a @click="goAnchor(item.link)">{{ item.name }}</a>
          </li>
          <!-- <li class="item_download" @click="downloadApp()">Apply now!</li> -->
        </ul>
      </div>
    </div>
    <!-- <img class="nav_bg" src="~@/assets/images/loanImg/nav_bg.png"> -->
  </div>
</template>

<script>
export default {
  props: {
    navData: {
      type: Object,
      default: function() {
        return null
      }
    }
  },
  methods: {
    goAnchor(selector) {
      if (selector === '#selector1') {
        const section = document.querySelector(selector)
        if (section) {
          console.log(selector === '#selector1')
          const topOffset = section.offsetTop + window.pageYOffset
          window.scrollTo({ top: topOffset, behavior: 'smooth' })
        }
      } else {
        this.$router.push(selector)
      }
    },
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=caudal.sube.credito', '_blank')
    }
  }
}
</script>

<style lang="scss" scope>
a {
  transition: color 0.3s ease;
}
.nav-inner {
  width: calc(100vw);
  background: #FFFFFF;
  padding:0px calc((100% - 1100px) / 2) 0;
  position: relative;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 99999;
  .nav-tab {
    height: 70px;
    align-items: center;
    margin: 0 auto !important;
    position: relative;
    z-index: 99999;
    // padding: 10px 0;
    .tab{
      margin: 0 auto;
    }
    .hotel-logo {
      float: left;
      height: 70px;
      line-height: 70px;
      color: white;
      span:nth-child(1) {
        display: inline-block;
        width: 44px;
        height: 70px;
        vertical-align:middle;
        background: url("~@/assets/images/loanImg/logo_icon_2.png") no-repeat center;
        background-size: contain;
      }
      span:nth-child(2){
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        vertical-align:middle;
        margin-left: 10px;
        color: #333333;
      }
      // .logo-img{
      //   background: url("~@/assets/images/loanImg/logo_2.png") no-repeat center;
      // }
    }
    .hotel-li {
      float: right;
      height: 100%;
      li {
        display: inline-block;
        position: relative;
        // width: 16.66%;
        padding: 0 20px;
        height: 100%;
        font-size: 16px;
        text-align: center;
        line-height: 70px;
        white-space:nowrap;
        cursor: pointer;
        a{
          color: #333333;
          text-decoration: none;
        }
        &.under-line{
          color: $color-6E60F9;
          a{
            font-weight: bold;
            color: #1265FB;
          }
          // &::after{
          //   position: absolute;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   bottom: -2px;
          //   content: '';
          //   width: 70px;
          //   height: 3px;
          //   background: #1265FB;
          // }
        }
      }
    }
  }
  .nav_bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 10%
  }
  .item_download{
    background: #007BFF;
    width: 140px !important;
    height: 40px !important;
    line-height: 40px !important;
    color: white;
    border-radius: 10px;
    margin-left: 50px;
  }
}
@media only screen and (max-width: 665px) {
  .nav-tab {
    height:70px!important;
    width: calc(100vw);
    z-index: 99999;
    .tab{
      .item_download{
        display: none !important;
      }
    }
    .hotel-logo{
      .logo-img{
        margin-left: 20px;
        width: 40px !important;
        height: 60px !important;
      }
    }
    .hotel-li{
      background: #FFFFFF;
      width: calc(100vw);
      display: flex;
      li{
        line-height: 50px !important;
        padding: 0 10px !important;
      }
      .select-item {
        flex: 1;
      }
      a{
        font-size: 12px !important;
      }
    }
  }
  .nav_bg{
    width: 50px !important;
  }
}
</style>
